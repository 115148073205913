
    import { Component, PropSync, Vue } from "vue-property-decorator";
    import AuthenticationModalPayload from "@/store/modules/authenticationModal/models";
    import { AxiosError } from "axios";
    import RoutesEnum from "@/router/routesEnum";
    import FormInscription from "@/app/authentication/views/FormInscription.vue";
    import InscriptionSucess from "@/app/authentication/views/InscriptionSucess.vue";

    @Component
    export default class InscriptionPage extends Vue
    {
        private firstName: string = "";
        private lastName: string = "";
        private email: string = "";
        private telephone: string = "";
        private password: string = "";
        private confirmPassword: string = "";
        sectionDisplayed: number = 0;

        private checkedTerms: boolean = false;

        get components()
        {
            let components = {
                "form-inscription": FormInscription,
                "inscription-sucess": InscriptionSucess
            };

            return components;
        }

        get getIsPasswordMatch(): boolean
        {
            return this.password === this.confirmPassword;
        }

        onClickHome()
        {
            this.$router.push({ name: RoutesEnum.Package });
        }
    }
