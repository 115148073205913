
    import { Component, PropSync, Vue } from "vue-property-decorator";
    import AuthenticationModalPayload from "@/store/modules/authenticationModal/models";
    import { AxiosError } from "axios";
    import RoutesEnum from "@/router/routesEnum";

    @Component
    export default class InscriptionSucess extends Vue
    {
        private firstName: string = "";
        private lastName: string = "";
        private email: string = "";
        private telephone: string = "";
        private password: string = "";
        private confirmPassword: string = "";
        private checkedTerms: boolean = false;

        get getIsPasswordMatch(): boolean
        {
            return this.password === this.confirmPassword;
        }

        onClickHome()
        {
            this.$router.push({ name: RoutesEnum.Package });
        }
        onclickOpenConnectionBox()
        {
            this.$store.commit(new AuthenticationModalPayload.Mutations.DisplayAuthenticationModal(0));
        }
    }
