
    import { Component, PropSync, Vue } from "vue-property-decorator";
    import { AxiosError } from "axios";
    import RoutesEnum from "@/router/routesEnum";
    import { LoginRegisterPaxBody } from "@/apiManager/login/loginData";
    import { ErrorServerCodeEnum, RezToursLanguageEnum } from "@/apiManager/_common/enums";

	@Component
    export default class FormInscription extends Vue
    {
		private firstName: string = "";
		private lastName: string = "";
		private email: string = "";
		private telephone: string = "";
		private password: string = "";
		private confirmPassword: string = "";
		private hasErrorServer: boolean = false;
		private errorServerMessage: string = "";
		private registerInProgress: boolean = false;

		@PropSync("sectionDisplayed", { type: Number })
		private syncedSectionDisplayed!: number;

		private checkedTerms: boolean = true;

		get getIsPasswordMatch(): boolean
		{
			return this.password === this.confirmPassword;
		}

		onClickHome()
		{
			this.$router.push({ name: RoutesEnum.Package });
		}

		onClickSubscriptionSend()
		{
			this.syncedSectionDisplayed = 1;
		}

		async onClickSubmit()
		{
			this.hasErrorServer = false;

			if (!this.checkedTerms)
			{
				// TODO: Show message error terms and condition not checked
				return;
			}

			if (!this.getIsPasswordMatch)
			{
				// TODO: Show message error password and confirmPassword don't match
				return;
			}

			if (
					this.errorName === true ||
					this.errorLastName === true ||
					this.errorEmail === true ||
					this.errorTelephone === true ||
					this.errorPassword === true ||
					this.errorConfirmPassword === true ||
					this.firstName.trim().length === 0 ||
					this.lastName.trim().length === 0 ||
					this.email.trim().length === 0 ||
					this.telephone.trim().length === 0 ||
					this.password.trim().length === 0 ||
					this.confirmPassword.trim().length === 0
				)
				{
					return;
				}

			try
			{
				this.registerInProgress = true;

                let body: LoginRegisterPaxBody = new LoginRegisterPaxBody();
                body.email = this.email;
                body.firstName = this.firstName;
                body.lastName = this.lastName;
                body.telephone = this.telephone;
                body.password = this.password;
                body.lang = this.g_Language;

				await this.$apiManager.login.registerPaxAsync(body);

				this.onClickSubscriptionSend();
			}
			catch (ex)
			{
				switch((ex as AxiosError)?.response?.data?.codeError)
				{
                    case ErrorServerCodeEnum.UserCreateEmailAlreadyUsed:
                        this.errorServerMessage = this.i18n(RezToursLanguageEnum.errorAuthEmailAlreayUsed);
                        break;
                    case ErrorServerCodeEnum.ActionFailed:
                        this.errorServerMessage = this.i18n(RezToursLanguageEnum.errorAuthCreate);
                        break;
                    case ErrorServerCodeEnum.CommunicationErrorWithPCVWeb:
                        this.errorServerMessage = this.i18n(RezToursLanguageEnum.errorCommPCVWeb);
						break;
					case ErrorServerCodeEnum.UserCreatePaxAlreadyExist:
						this.errorServerMessage = this.i18n(RezToursLanguageEnum.errorAuthPaxAlreayExist);
						break;
                    default:
                        this.errorServerMessage = this.i18n(RezToursLanguageEnum.serverErrorMsg);
                }
                this.hasErrorServer = true;
			}
			finally
			{
				this.registerInProgress = false;
			}
		}

		get errorName()
		{
			if (this.firstName.length > 0)
			{
				let format = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;

				if (this.firstName.match(format))
				{
					return false;
				}
				else
				{
					return true;
				}
			}
		}

		get errorLastName()
		{
			if (this.lastName.length > 0)
			{
				let format = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;

				if (this.lastName.match(format))
				{
					return false;
				}
				else
				{
					return true;
				}
			}
		}

		get errorEmail()
		{
			if (this.email.length > 0)
			{
				if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email))
				{
					return false;
				}
				else
				{
					return true;
				}
			}
		}

		get errorTelephone()
		{
			if (this.telephone.length > 0)
			{
				let phoneno = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
				if (this.telephone.match(phoneno))
				{
					return false;
				}
				else
				{
					return true;
				}
			}
		}

		get errorPassword()
		{
			if (this.password.length > 0)
			{
				if (this.password.length < 17)
				{
					return false;
				}
				else
				{
					return true;
				}
			}
		}

		get errorConfirmPassword()
		{
			if (this.confirmPassword.length > 0)
			{
				if (this.confirmPassword === this.password)
				{
					return false;
				}
				else
				{
					return true;
				}
			}
		}
    }
